import DestinationsEditBaseController from "controllers/destinations/edit/base_controller"

export default class extends DestinationsEditBaseController {
  connect() {
    this.onConnect();
    this.ensureActiveCollectionTab();
  }

  ensureActiveCollectionTab() {
    this.setCollectionTab(
      this.getDefaultActiveCollectionTabId()
    );
  }

  onSetCollectionTab(e) {
    this.setCollectionTab($(e.target).data('collection-tab-id'));
  }

  setCollectionTab(collectionTabId) {
    this.$('.collection-tab').hide();
    this.$('.side-tab-link')
      .removeClass('side-tab-link-active');

    $(`#${collectionTabId}`).show();
    $(`#${collectionTabId}-link`)
      .addClass('side-tab-link-active');
  }
}
