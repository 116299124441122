import ApplicationController from "controllers/application_controller"
import Cookies from 'js-cookie';

export default class extends ApplicationController {
  connect() {
    this.onConnect();

    Messaging.interpretFlash();

    this.storeGoogleUaClientId();
    this.modules();
  }

  openModal(e) {
    const modalSelector = $(e.target).closest('[data-modal-selector]').data('modal-selector');
    $(modalSelector).modal();
  }

  openAjaxModal(e) {
    const $target = $(e.target).closest('[data-modal-url]');
    const url = $target.data('modal-url');

    if(!url) return;

    $.openAjaxModal(url, $target.data('modal-title'));
  }

  storeGoogleUaClientId() {
    if(Wordable.global.environment == 'test') return;

    const userSettings = {};
    const gaCookieUserId = this.getGaCookieUserId();

    if(!gaCookieUserId) return;

    userSettings.google_ua_client_id = gaCookieUserId;
    userSettings.google_a4_client_id = gaCookieUserId;

    const sessionIdPromise =  new Promise(resolve => {
      gtag('get', Wordable.config.GOOGLE_ANALYTICS_4_MEASUREMENT_ID, 'session_id', resolve)
    });

    const sessionNumPromise =  new Promise(resolve => {
      gtag('get', Wordable.config.GOOGLE_ANALYTICS_4_MEASUREMENT_ID, 'session_number', resolve)
    });

    Promise.all([sessionIdPromise, sessionNumPromise]).then(function(session_data) {
      userSettings.google_a4_session_id = session_data[0];
      userSettings.google_a4_session_number = session_data[1];

      if(userSettings.google_a4_session_id == Wordable.global?.current_user?.settings?.google_a4_session_id &&
         userSettings.google_a4_session_number == Wordable.global?.current_user?.settings?.google_a4_session_number &&
         userSettings.google_a4_client_id == Wordable.global?.current_user?.settings?.google_a4_client_id
        )
        return;

      if(Wordable?.global?.current_user?.id) {
        API.ajax({
          url: `/users/${Wordable.global.current_user.id}`,
          method: 'PUT',
          data: { user: { settings: userSettings } }
        });

        Cookies.remove('newUserSettings');
      } else {
        Cookies.set('newUserSettings', JSON.stringify(userSettings));
      }
    });
  }

  getGaCookieUserId() {
    const gaCookie = getCookie('_ga');

    if(gaCookie.length <= 1)
      return false;

    const parts = gaCookie.split('.');

    return [parts[parts.length -2], parts[parts.length - 1]].join('.');
  }

  gaTrack(e) {
    handleGa4TrackEvent(e);
  }
}
