const DestinationSpecificCallbacks = {
  contentful: {
    updateGlobalDestinationForms: (stimulusController, destination) => {
      console.log("stimulusController", stimulusController);
      // const destinationMeta = stimulusController.state.export.destination[destination.id].destination_meta;
      // $('.destination-contentful-content-type-select-dropdown').hide();
      // $(`.destination-contentful-${destinationMeta.environment_id}-content-type-select-dropdown`).show();
    },

    onBeforeSave: (stimulusController, destination) => {
      console.log("stimulusController", stimulusController);
      // const destinationMeta = stimulusController.state.export.destination[destination.id].destination_meta;
      // $(`.destination-contentful-content-type-select-dropdown[class^="destination-contentful-${destinationMeta.environment_id}-content-type-select-dropdown"]`).remove();
    }
  }
}

const ExportOptionsFormConcern = {
  setupForm() {
    const queryParams = [];
    const formIdSuffixParts = [];

    if(this.state.destination_id) {
      formIdSuffixParts.push(this.state.destination_id);
      queryParams.push(`destination_id=${this.state.destination_id}`);
    } else {
      formIdSuffixParts.push(0);
    }

    if(this.state.destination_export_template_id) {
      formIdSuffixParts.push(this.state.destination_export_template_id);
      queryParams.push(`destination_export_template_id=${this.state.destination_export_template_id}`);
    } else {
      formIdSuffixParts.push(0);
    }

    if(this.state.base_export?.id) {
      formIdSuffixParts.push(this.state.base_export?.id);
      queryParams.push(`base_export_id=${this.state.base_export.id}`);
    } else {
      formIdSuffixParts.push(0);
    }

    if(this.state.base_export?.update_previous) {
      queryParams.push(`update_previous=${this.state.base_export.update_previous}`);
    }

    let formSelector = null;

    if(!formIdSuffixParts.every((i) => {
      return i == 0;
    })) {
      formSelector = `export-options-form-${formIdSuffixParts.join('-')}`;
    }

    if(!formSelector) {
      this.onFormSetup();
      this.showEmpty();
      return;
    }

    queryParams.push(`stimullus_controller=${this.stimulusController}`);
    let $form = $(formSelector);

    if($form.length > 0) {
      this.onFormSetup();
    } else {
      this.showLoading();

      API.ajax({
        url: `/${this.optionsFormController}/options_form.json?${queryParams.join('&')}`,
        method: 'GET',
        complete: ({ responseJSON }) => {
          if(!responseJSON) return;
          this.$exportOptionsForm.html(responseJSON.form);
          this.onFormSetup();
        }
      });
    }
  },

  onFormSetup() {
    this.state.export_options = {};
    this.serializeInputsToMember(this.$exportOptionsForm);
    this.showExportOptionsForm();
    if(this.onAfterFormSetup) this.onAfterFormSetup();
    installAll();
  },

  showExportOptionsForm() {
    if(this.$empty) this.$empty.hide();

    if(this.$loading) {
      this.$exportOptionsFormWrapper.show();
      this.$loading.hide();
    } else {
      this.enableNode(this.$exportOptionsFormWrapper);
    }
  },

  showLoading() {
    if(this.$empty) this.$empty.hide();
    if(this.$loading) {
      this.$exportOptionsFormWrapper.hide();
      this.$loading.show();
    } else {
      this.disableNode(this.$exportOptionsFormWrapper);
    }
  },

  showEmpty() {
    if(this.$empty) this.$empty.show();
    this.$loading.hide();
    this.$exportOptionsFormWrapper.hide();
  },

  createDestinationExportTemplate() {
    this.onBeforeSave();
    this.disableElement();

    API.ajax({
      url: `/destination_export_templates.json`,
      method: 'POST',
      data: { destination_export_template: this.getDestinationExportTemplateFormAttributes() },
      complete: this.afterSaveDestinationExportTemplate
    });
  },

  updateDestinationExportTemplate() {
    this.onBeforeSave();
    this.disableElement();

    API.ajax({
      url: `/destination_export_templates/${this.state.destination_export_template_id}.json`,
      method: 'PUT',
      data: { destination_export_template: this.getDestinationExportTemplateFormAttributes() },
      complete: this.afterSaveDestinationExportTemplate
    })
  },

  getBaseDestinationExportTemplateFormAttributes() {
    return {
      options: this.getExportOptionsFormAttributes(),
      destination_id: this.state.destination_id
    };
  },

  getExportOptionsFormAttributes() {
    const exportOptions = JSON.parse(JSON.stringify(this.state.export_options));

    exportOptions.transformations =
      Object.values(exportOptions.transformations).filter((transformation) => {
        return transformation.selected;
      });

    return exportOptions;
  },

  onStateExportOptionsTransformationsSelectedChanged({ name, target }) {
    const $input = $(target);

    if($input.is(':checked')) {
      $input.closest('.transformation-wrapper').find('.grouping-component-card-nested').show();
    } else {
      $input.closest('.transformation-wrapper').find('.grouping-component-card-nested').hide();
    }
  },

  onBeforeSave() {
    this.destinationSpecificCallback('onBeforeSave');
  },

  destinationSpecificCallback(callbackName) {
    const destinationId = parseInt(this.state.destination_id);

    const destination = this.state.destinations.find((destination) => {
      return destination.id == destinationId;
    });

    const destinationCallbacks = DestinationSpecificCallbacks[destination.category];
    if(!destinationCallbacks) return;

    if(typeof destinationCallbacks[callbackName] != 'function') return;

    destinationCallbacks[callbackName](this, destination);
  },

  selectedDestinationExportTemplate() {
    if(!this.state.destination_export_template_id || !this.destination_export_templates_map) return null;

    return this.destination_export_templates_map[this.state.destination_export_template_id];
  },

  deepComparableExportOptions(exportOptions) {
    let comparableExportOptions = JSON.parse(JSON.stringify(exportOptions));

    let originalTransformations = comparableExportOptions.transformations;
    comparableExportOptions.transformations = {};

    originalTransformations.forEach((transformation) => {
      comparableExportOptions.transformations[transformation.id] = transformation;
    });

    return comparableExportOptions;
  }
}

export default ExportOptionsFormConcern;
