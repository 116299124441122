import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [];

  connect() {
    this.onConnect();
    this.setCurrentFields();
    this.discoverFieldTemplate();
  }

  repeatableFieldKeyup(e) {
    const changedField = $(e.target);

    const lastFieldValue = this.getLastField().val();

    if(lastFieldValue != '') {
      const newField = $(this.fieldTemplate);
      this.$element.append(newField);
      this.currentFields.push(newField);
    }
  }

  getLastField() {
    return this.currentFields[this.currentFields.length - 1];
  }

  repeatableFieldChanged(e) {
    const changedField = $(e.target);

    if(changedField.val() == '') {
      changedField.fadeOutAndRemove();
    }

    this.setCurrentFields();
  }

  setCurrentFields() {
    this.currentFields = [];

    this.$('input').each((i, field) => {
      const $field = $(field);

      if($field.attr('id') == undefined) {
        $field.attr('id', `${this.state.idPrefix}-${Date.now()}-${i}`);
      }

      const fieldName = $field.attr('name');
      const fieldNameParts = fieldName.split('][');
      const constantFieldNameParts = fieldNameParts.slice(0, fieldNameParts.length - 1);
      const newFieldName = `${constantFieldNameParts.join('][')}][${i}]`;
      $field.attr('name', newFieldName);

      this.currentFields.push($field);
    });
  }

  discoverFieldTemplate() {
    this.fieldTemplate = this.getLastField().outerHtml();
  }
}
