import ApplicationController from "controllers/application_controller";
import DocumentsConcern from "controllers/concerns/documents";
import ExportOptionsFormConcern from "controllers/concerns/export_options_form";
import deepCompare from "modules/deep_compare";

export default class extends ApplicationController {
  static concerns = [DocumentsConcern, ExportOptionsFormConcern];
  static targets = [
    'exportButton',
    'main',
    'mainLoading',

    'destinationTemplatesSelector',
    'destinationTemplatesLoading',

    'spinner',
    'exportOptionsForm',
    'exportOptionsFormWrapper',
    'createDestinationExportTemplate',
    'updateDestinationExportTemplate',
    'destinationExportTemplateUpdateButton',
    'destinationExportTemplateCreateButton'
  ];

  connect() {
    this.onConnect();
    this.stimulusController = 'exports--new';
    this.optionsFormController = 'exports';
    this.exportOptionsStateKey = 'export';

    const params = new URLSearchParams(window.location.search);

    this.$destinationExportTemplateUpdateButton.on('click', this.updateDestinationExportTemplate);
    this.$destinationExportTemplateCreateButton.on('click', this.createDestinationExportTemplate);

    this.loadDestinationTemplates();

    // Helpers to skip export directly to template management
    this.devSkipToCreateTemplate = params.has('skip_to_create_template');
    this.devSkipToUpdateTemplate = params.has('skip_to_update_template');
    this.devSkipToTemplates = this.devSkipToCreateTemplate || this.devSkipToUpdateTemplate;
    this.devPretendToExport = params.has('pretend_to_export');

    if(this.devSkipToTemplates) {
      setTimeout(this.createBulk, 2000);
    }
  }

  setupUi() {
    this.serializeInputsToMember(this.$destinationTemplatesSelector);
    this.ensureDestroySelectedTemplateState(this.state.destination_export_template_id);
    this.setupForm();
  }

  allDocuments() {
    return this.state.documents;
  }

  onStateDestinationIdChanged(e) {
    this.loadDestinationTemplates();
  }

  onStateDestinationExportTemplateIdChanged(e) {
    this.setupUi();
  }

  onAfterFormSetup() {
    this.$main.show();
    this.$mainLoading.hide();

    if(!this.saveOriginalExportOptions) return;

    this.saveOriginalExportOptions = false;
    this.originalExportOptions = this.deepComparableExportOptions(this.getExportOptionsFormAttributes());
    this.originalExportOptions.destination_export_template_id = this.state.destination_export_template_id;
  }

  loadDestinationTemplates() {
    let wrapperSelector = null;

    const queryParams = [];

    if(this.state.destination_id) {
      wrapperSelector = `#destination-export-template-selector-${this.state.destination_id}`;
      queryParams.push(`destination_id=${this.state.destination_id}`);
    }

    if(this.state.base_export &&
       this.state.base_export.destination_id == this.state.destination_id) {

      queryParams.push(`base_export_id=${this.state.base_export.id}`);
      queryParams.push(`destination_export_template_id=-1`);
    } else if(this.state.destination_export_template_id != -1) {
      queryParams.push(`destination_export_template_id=${this.state.destination_export_template_id}`);
    }

    if(!wrapperSelector) return;

    queryParams.push(`stimullus_controller=${this.stimulusController}`);
    let $wrapper = $(wrapperSelector);

    if($wrapper.length == 0) {
      this.$destinationTemplatesLoading.show();
      this.$destinationTemplatesSelector.hide();
      this.showLoading();

      API.ajax({
        url: `/exports/destination_export_template_selector.json?${queryParams.join('&')}`,
        method: 'GET',
        complete: ({ responseJSON }) => {
          if(!responseJSON) return;
          this.destination_export_templates_map = responseJSON.destination_export_templates_map;
          this.$destinationTemplatesSelector.html(responseJSON.selector);
          this.$destinationTemplatesLoading.hide();
          this.$destinationTemplatesSelector.show();
          this.saveOriginalExportOptions = true;
          this.setupUi();
        }
      });
    }
  }

  // Exporting
  createBulk() {
    this.$exportButton.hide();
    this.disableElement();

    this.onBeforeSave();
    const exportsAttributes = [];
    const exportOptions = this.getExportOptionsFormAttributes();
    exportOptions.destination_export_template_id = this.state.destination_export_template_id;

    this.state.documents.forEach((document) => {
      exportsAttributes.push({
        destination_id: this.state.destination_id,
        document_id: document.id,
        options: exportOptions
      });
    })

    if(this.devSkipToTemplates || this.devPretendToExport) {
      return this.afterCreateBulk(exportOptions);
    }

    API.ajax({
      url: `/exports/bulk_create`,
      method: 'POST',
      data: { exports_attributes: exportsAttributes },
      complete: () => {
        return this.afterCreateBulk(exportOptions);
      }
    });
  }

  // Templates
  ensureDestroySelectedTemplateState(destination_export_template_id) {
    if(destination_export_template_id && destination_export_template_id != -1) {
      this.$('.destroy-selected-template-button').show();
    } else {
      this.$('.destroy-selected-template-button').hide();
    }
  }

  confirmDestroySelectedTemplate() {
    const selectedDestinationExportTemplate = this.selectedDestinationExportTemplate();

    if(!selectedDestinationExportTemplate) return;

    const detailText = `<div class="text-sm mt-h text-gray-500 italic">This action cannot be undone.</div>`;

    dataConfirmModal.confirm({
      text: `<div class="text-red-base text-lg mb-h">You are about to delete the template ${selectedDestinationExportTemplate.name}</div> ${detailText}`,
      onConfirm: this.destroySelectedTemplate,
    });
  }

  destroySelectedTemplate() {
    const selectedDestinationExportTemplate = this.selectedDestinationExportTemplate();
    this.$destinationTemplatesLoading.show();
    this.$destinationTemplatesSelector.html('');

    API.ajax({
      url: `/destination_export_templates/${selectedDestinationExportTemplate.id}`,
      method: 'DELETE',
      complete: () => {
        this.loadDestinationTemplates();
      }
    })
  }

  afterCreateBulk(exportOptions) {
    if(this.devSkipToTemplates ||
       !exportOptions.destination_export_template_id ||
       !deepCompare(this.deepComparableExportOptions(exportOptions), this.originalExportOptions)) {
      this.setupDestinationExportTemplateForm(exportOptions);
    } else {
      this.afterSaveDestinationExportTemplate();
    }
  }

  setupDestinationExportTemplateForm(exportOptions) {
    this.$spinner.hide();
    this.enableElement();

    let usedTemplate = this.selectedDestinationExportTemplate();

    if(usedTemplate) {
      $("[name='state[destination_export_template][name]']")
        .changeTo(usedTemplate.name);
    }

    $('.destination-export-template-disposable-section').hide();

    if(((exportOptions.destination_export_template_id &&
         exportOptions.destination_export_template_id != -1) &&
        !this.devSkipToCreateTemplate) || this.devSkipToUpdateTemplate) {
      this.$updateDestinationExportTemplate.fadeIn();
      this.$createDestinationExportTemplate.remove();
    } else {
      this.$createDestinationExportTemplate.fadeIn();
      this.$updateDestinationExportTemplate.remove();
    }

    $('.main-content').animate({
      scrollTop: 0
    });
  }

  getDestinationExportTemplateFormAttributes() {
    return Object.assign(
      {},
      this.state.destination_export_template,
      this.getBaseDestinationExportTemplateFormAttributes()
    );
  }

  afterSaveDestinationExportTemplate() {
    if(this.devSkipToTemplates) return;

    window.location = '/?nps=1';
  }
}
